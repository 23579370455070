export type MText = string | {
    content : MText;
    style : Record<string,string>;
} | MText[];

/**
 * A general component that makes up a templated document.
 *
 * The templated converts the parsed document into this format, which is then rendered. The editor
 * works in this format, since it describes what the user sees, not what the document semantics are
 * (i.e. it doesn't say what's a job title, date, etc).
 *
 * The appearance of the components is dependent on the theme; the theme is assumed to be
 * reasonable.
 */
export type Component = {
    /**
     * Top level heading.
     *
     * When rendered as HTML, the content is simply wrapped in a `h1` element.
     */
    h1: MText;
} | {
    /**
     * Second level heading.
     *
     * When rendered as HTML, the content is simply wrapped in a `h2` element.
     */
    h2: MText;
} | {
    /**
     * Third level heading.
     *
     * When rendered as HTML, the content is simply wrapped in a `h3` element.
     */
    h3: MText;
} | {
    /**
     * A paragraph of text.
     *
     * When rendered as HTML, the content is simply wrapped in a `p` element.
     */
    p: MText;
} | {
    /**
     * A span of inline text.
     *
     * When rendered as HTML, the content is simply wrapped in a `span` element, unless it's the
     * only child, in which case it may be included directly.
     */
    span: MText;
} | {
    /**
     * A list of bullet points, each bullet point is a list of components.
     *
     * When rendered as HTML, each list item is wrapped in a `li` element, which are all inside a
     * single `ul` component.
     */
    ul: Component[][];
} | {
    /**
     * Components to be rendered left-to-right.
     *
     * This can be used to construct a line with a heading, along with some text to the right.
     *
     * When rendered as HTML, the contained components are rendered within a `div` with the class
     * name `"simple-row"`.
     */
    row: Component[];
} | {
    /**
     * Content (which might be an address) to show right of the templated document.
     *
     * When rendered as HTML, the content is wrapped in an `address` element with the class name
     * `"address-right"`.
     */
    addressRight: MText;
} | {
    table: Component[][][];
}

/**
 * A document to render, is has a theme (class 
 */
export type Doc = {
    /**
     * The components to render!
     */
    components: Component[],

    /**
     * The class name to be applied to the containing element.
     */
    theme: string,

    /**
     * Page width, in inches.
     */
    page_width: number,
    /**
    * Page width, in inches.
    */
    page_height: number,

    /**
     * Right margin, in inches.
     */
    page_margin_top: number,
    /**
     * Right margin, in inches.
     */
    page_margin_bottom: number,
    /**
     * Right margin, in inches.
     */
    page_margin_left: number,
    /**
     * Right margin, in inches.
     */
    page_margin_right: number,

    /**
     * So.... If you need to do fancy stuff with the margins of the page, here's how you do it:
     *
     * 1. Set the page margin to zero.
     * 2. Give you container div some padding, this will act as the page margin.
     * 3. Put your fancy stuff in the div padding.
     * 4. Be very sorry.
     * 5. Set `i_am_sorry` to true.
     * 6. Be more sorry.
     * 7. This will run some horrible code that inserts a gap of at least size
     *    `paddingTop + paddingBottom` between items where there's a page break.
     *    Essentially, we do the page break handling ourselves.
     *
     * Editor note: when in the editor, the page breaks aren't inserted. When page breaks are
     * inserted, however (currently only when generating the PDF), the "with-pages" class is added.
     * If you're applying CSS that depends on the page breaks, for example background images, these
     * should only be applied to `.templated-doc.with-pages.your-theme`, instead of just
     * `.templated-doc.with-pages.your-theme`.
     */
    i_am_sorry: boolean,
}

/**
 * Create a HTML element from a component.
 */
function createComponentElement(component: Component): HTMLElement {
    const textElements = ["h1", "h2", "h3", "p", "span"];
    for (const textElement of textElements) {
        if (textElement in component) {
            const elem = document.createElement(textElement);
            // @ts-ignore this is fine, typescript thinks I can't index with a string
            elem.append(...createTextNodes(component[textElement]));
            return elem;
        }
    }

    if ("ul" in component) {
        const ul = document.createElement("ul");
        for (const item of component.ul) {
            const li = document.createElement("li");
            if (item.length === 1 && "p" in item[0])
                li.append(...createTextNodes(item[0].p))
            else if (item.length === 1 && "span" in item[0])
                li.append(...createTextNodes(item[0].span))
            else
                li.append(...item.map(createComponentElement));
            ul.appendChild(li);

        }
        return ul;
    }

    if ("row" in component) {
        const row = document.createElement("div");
        row.classList.add("simple-row");
        row.append(...component.row.map(createComponentElement));
        return row;
    }

    if ("addressRight" in component) {
        const elem = document.createElement("address");
        elem.classList.add("address-right");
        elem.append(...createTextNodes(component.addressRight));
        return elem;
    }

    if ("table" in component) {
        const table = document.createElement("table");
        for (const row of component.table) {
            const rowElem = document.createElement("tr");
            for (const item of row) {
                const itemElem = document.createElement("td");
                itemElem.append(...item.map(createComponentElement));
                rowElem.append(itemElem);
            }
            table.append(rowElem)
        }
        return table
    }

    throw new Error("invalid component: " + JSON.stringify(component));
}

/**
 * Generate the HTML of a document. This will return a single div, containing all the components.
 *
 * This ignores the document size and margin, the parent element should handle these properties.
 */
export function renderDoc(doc: Doc): HTMLElement {
    const div = document.createElement("div");
    div.classList.add("templated-doc", doc.theme)
    div.append(...doc.components.map(createComponentElement));
    return div;
}

/**
 * Take a string, which must contain the "px" suffix, and remove the suffix before parsing it as a
 * base 10 float.
 */
function parsePixels(s: string): number {
    if (!s.endsWith("px"))
        throw new Error("value was not computed in pixels: " + s);
    return parseFloat(s.slice(0, s.length - 2));
}

/**
 * Make the padding of `container` act as page margins.
 *
 * That is, space elements out such that nothing is within the padding of a page break.
 *
 * The page breaks are determined by `pageHeightInches`, which is the intended height of each page,
 * in inches of course!
 */
function addPageBreaks(pageWidthInches: number, pageHeightInches: number, container: HTMLElement) {
    // We start by setting the width of the container to the width of the page.
    // If we don't do this, line wraps won't happen in the right place, so all our calculations will
    // be wrong!
    container.style.boxSizing = "border-box";
    container.style.width = `${pageWidthInches}in`;

    // Get the top and bottom margin (actually padding)
    const containerStyle = getComputedStyle(container);
    const paddingTop = parsePixels(containerStyle.paddingTop);
    const paddingBottom = parsePixels(containerStyle.paddingBottom);

    // Calculate the height of the page, in pixels.
    // We do this by appending a div with `height` set to the page height, and letting CSS calculate
    // the size in pixels.
    const heightTester = document.createElement("div");
    heightTester.style.height = `${pageHeightInches}in`;
    heightTester.style.display = "none";
    document.body.appendChild(heightTester);
    const pageHeight = parsePixels(getComputedStyle(heightTester).height);
    document.body.removeChild(heightTester);

    // This tracks which page we're on. We start counting at 1. This makes sense, I promise.
    let page = 1;

    /**
     * Process an element which may need padding adjusting to give a gap over a page break.
     */
    function processNode(node: Element) {
        // We only add padding to our basic elements.
        //
        // For any other elements, we process all their children (see the else block).
        //
        // Also, "TR" exists, and is handled slightly differently, see below.
        if (
            ["H1", "H2", "H3", "P", "LI", "ADDRESS", "TR"].includes(node.nodeName)
                || node.nodeName === "DIV" && node.classList.contains("simple-row")
        ) {
            const elem = node as HTMLElement;
            const rect = elem.getBoundingClientRect();

            // We need to check for overflow on every page (since this element could be no lower
            // than the previous element, for example in a table row).
            // We check backwards, since we only need to add padding for the last page it's on.
            for (let checkPage = page; checkPage > 0; checkPage--) {
                // If the bottom of the current element falls below where we should cut the page
                // off, we add padding to move it to the top of the next page.
                const pageBottom = pageHeight * checkPage;
                if (rect.bottom > pageBottom - paddingBottom) {
                    // Table rows don't actually need to wrap (since later on we process and
                    // potentially wrap their children).
                    //
                    // But, it's neater if small rows do. We therefore only wrap if the top is close
                    // enough to the bottom of the page.
                    if (elem.nodeName === "TR" && rect.top < pageBottom - paddingBottom * 3)
                        break;

                    // We intend to set the padding top to:
                    // - The gap between but bottom of the page, and the top of the element
                    //   (this moves the element to the top of the next page)
                    // - Plus the paddingTop (to add the top margin)
                    // - Plus the original padding of the element
                    //
                    // To note here: if the element was actually already on the next page, then
                    // `gap` has the side-effect of correcting for this, so we don't break anything!
                    let gap = pageBottom - rect.top;
                    // To avoid editing the padding on everything past the first page, however, we
                    // don't bother if the gap is already large enough! It doesn't break the
                    // appearance without this, but it feels neat to add it.
                    if (gap < -paddingTop) continue;

                    // Something else to think about... When actually printing, the padding isn't
                    // from the last element, but instead from the top of the page. So, in this
                    // case, the gap should max out at 0.
                    //
                    // We can't apply this now, however. If we did, our calculations wouldn't work
                    // because, on the page we're working with, the padding is still from the
                    // previous element.
                    //
                    // This function does this for us!
                    applyExtraPaddingTop(elem, gap + paddingTop, Math.min(gap, 0) + paddingTop);

                    // Remove the top margin, this this has been "merged into the page margin"
                    elem.style.marginTop = "0px";

                    // We're nearly there.
                    //
                    // Yes, I've been doing this for more than 24 hours now.
                    //
                    // Thanks to the print logic above, we might no longer have enough padding and
                    // margin to force a page break naturally. So we need to force it.
                    elem.style.pageBreakBefore = "always";

                    // If we've processed an element that's been moved to a new page, we're now on
                    // the next page, so increment the page counter!
                    if (checkPage === page) page++;
                    break;
                }
            }

            // Finally, content within table rows should also wrap itself.
            if (elem.nodeName === "TR")
                [...node.children].forEach(processNode);
        } else {
            [...node.children].forEach(processNode);
        }
    }

    [...container.children].forEach(processNode);

    // Afterwards, to ensure the div background covers the entirety of the last page, set the
    // height to the exact height of the correct number of pages.
    container.style.height = `${page * pageHeightInches}in`;
}

/**
 * Add extra padding to the top of an element.
 *
 * `extraPaddingTop` is applied when not printing, `extraPaddingTopPrint` is applied when printing.
 *
 * This is achieved by setting the `--print-padding-top` CSS variable, which some handy css in
 * prelude.css applies for us (instead of the normal padding) only when printing!
 */
function applyExtraPaddingTop(elem: HTMLElement, extraPaddingTop: number, extraPaddingTopPrint: number) {
    if (elem.nodeName !== "TR") {
        // Most elements are fairly simple:
        //
        // We get the current padding
        const paddingTop = parsePixels(getComputedStyle(elem).paddingTop);
        // And the current print padding (which is either the normal padding, or the CSS variable if
        // it's set)
        const printPaddingStr = elem.style.getPropertyValue("--print-padding-top");
        const printPaddingTop = printPaddingStr ? parsePixels(printPaddingStr) : paddingTop;

        // Then we apply the new values!
        elem.style.paddingTop = `${paddingTop + extraPaddingTop}px`;
        elem.style.setProperty("--print-padding-top", `${printPaddingTop + extraPaddingTopPrint}px`);
        elem.classList.add("apply-print-padding-top");
    } else {
        // Table rows, however, can't have padding, so we have to instead apply it to the children.
        for (const td of elem.children) {
            applyExtraPaddingTop(
                td as HTMLElement,
                extraPaddingTop,
                extraPaddingTopPrint,
            );
        }
    }
}

/**
 * Clear the body, and insert only the rendered document.
 *
 * This ignores the document size and margin, the printer should handle these properties.
 */
function replaceBodyWithDoc(doc: Doc) {
    document.body.innerHTML = "";
    const div = renderDoc(doc);
    document.body.appendChild(div);
    if (doc.i_am_sorry) {
        div.classList.add("with-pages");
        addPageBreaks(doc.page_width, doc.page_height, div);
    }
}
// @ts-ignore
window.replaceBodyWithDoc = replaceBodyWithDoc;

export const styleElements = ["fontWeight", "textDecoration", "fontStyle", "fontVariantPosition", "fontVariant", "verticalAlign"]

function createTextNodes(text : MText) : Node[] {
    if (typeof text === "string") {
        const node = document.createElement("span");
        node.innerText = text;
        return [node];
    }

    if (Array.isArray(text)) {
        return text.map(createTextNodes).flat();
    }
    
    const result : HTMLElement = document.createElement("span");
    result.append(...createTextNodes(text.content));
    styleElements.forEach(elem => {
        if (elem in text.style)
            {
                const val = text.style[elem];
                if (elem === "fontWeight") elem = "font-weight";
                result.style.setProperty(elem, val);
            }
    })
    
    return [result];
}
