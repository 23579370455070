import {AsyncAuthWrapper} from '../frontend-auth/auth-wrapper';

import {Component, Doc, MText, renderDoc, styleElements} from "../template-render-html/component";

export class Templating {
    private authWrapper: AsyncAuthWrapper;
    private groupId?: string;
    private docId?: string;
    private doc?: Doc;
    private renderedDiv?: HTMLElement;
    public embedPdf: HTMLEmbedElement;
    private templateDiv: HTMLDivElement;
    private bar: boolean;

    constructor(authWrapper: AsyncAuthWrapper, embedPdf: HTMLEmbedElement, templateDiv: HTMLDivElement) {
        this.authWrapper = authWrapper;
        this.embedPdf = embedPdf;
        this.templateDiv = templateDiv;
        this.showToolbar = this.showToolbar.bind(this);
        this.removeToolbar = this.removeToolbar.bind(this);
        this.changeElement = this.changeElement.bind(this);
        this.hoverButtons = this.hoverButtons.bind(this);
        this.removeHoverButtons = this.removeHoverButtons.bind(this);
        this.keyHandler = this.keyHandler.bind(this);
        this.bar = false;
        this.templateDiv.addEventListener("mouseleave", this.removeHoverButtons);
        this.contextMenu = this.contextMenu.bind(this);

        this.addListeners = this.addListeners.bind(this);
        this.HTMLtoComponent = this.HTMLtoComponent.bind(this);
        this.parseComponent = this.parseComponent.bind(this);
        this.parse = this.parse.bind(this);

        document.addEventListener("click",ev=>{
            templateDiv.querySelectorAll("menu").forEach(menu => menu.remove());
        })
    }

    fetchAndEdit(groupId: string, docId: string) {
        this.groupId = groupId;
        this.docId = docId;
        // TODO: This only returns the first page of the document.
        // We should obviously allow the user to select a document.
        // Furthermore, we should show the user the document info!!
        console.log("Getting templated", groupId, docId);
        return Promise.all([
            this.authWrapper.fetch("/get-pdf", {
                method: 'POST',
                body: JSON.stringify({GroupId: groupId, DocId: docId}),
            }).then(resp => {
                if (!resp.ok) throw new Error('non 200 status');
                return resp.blob()
            }).then(blob => {
                this.embedPdf.src = URL.createObjectURL(blob) + "#navpanes=0";
            }),
            this.authWrapper.fetch('/get-templated-doc', {
                method: 'POST',
                body: groupId + "/" + docId,
            }).then(resp => {
                if (!resp.ok) throw new Error('non 200 status');
                return resp.json() as Promise<{doc: Doc}>;
            }).then(({doc}) => this.edit(doc)),
        ]);
    }

    edit(doc: Doc) {
        this.doc = doc;
        this.renderedDiv = renderDoc(doc);
        this.renderedDiv.querySelectorAll("h1, h2, h3, ul, p, address, table").forEach(node => {
            this.addListeners(node as HTMLElement);
        })

        this.templateDiv.style.paddingTop = doc.page_margin_top.toString() + "in";
        this.templateDiv.style.paddingBottom = doc.page_margin_bottom.toString() + "in";
        this.templateDiv.style.paddingLeft = doc.page_margin_left.toString() + "in";
        this.templateDiv.style.paddingRight = doc.page_margin_right.toString() + "in";
        this.templateDiv.style.width = doc.page_width.toString() + "in";

        this.templateDiv.innerHTML = "";
        this.templateDiv.appendChild(this.renderedDiv);
    }

    addListeners(elem: HTMLElement) {
        elem.contentEditable = "plaintext-only";
        elem.addEventListener("focus", this.showToolbar)
        elem.addEventListener("focusout", this.removeToolbar)
        elem.addEventListener("mouseover", () => this.hoverButtons(elem));
        elem.addEventListener("keydown", this.keyHandler)

        if (elem.nodeName === "TABLE")
            elem.addEventListener("contextmenu", this.contextMenu)
    }

    HTMLtoComponent(parent: HTMLElement): Component[] {
        const children = [...parent.childNodes];

        // If every child is a span or text node, we can just use parseComponent.
        if (children.every(child => child.nodeType === Node.TEXT_NODE || child.nodeName === "SPAN")) {
            return [{
                span: this.parseComponent(parent),
            }];
        }

        const components: Component[] = [];
        children.forEach(node => {
            if (node.nodeType === Node.TEXT_NODE) {
                components.push({span: node.textContent || ""});
                return;
            }

            const elem = node as HTMLElement;

            switch (elem.nodeName) {
                case "H1":
                    components.push({h1: this.parseComponent(elem)})
                    break;
                case "H2":
                    components.push({h2: this.parseComponent(elem)})
                    break;
                case "H3":
                    components.push({h3: this.parseComponent(elem)})
                    break;
                case "P":
                    components.push({p: this.parseComponent(elem)})
                    break;
                case "ADDRESS":
                    if (elem.classList.contains("address-right")) {
                        components.push({addressRight: this.parseComponent(elem)})
                    }
                    break;
                case "UL":
                    components.push({ul: ([...elem.children] as HTMLElement[]).filter(child => child.nodeName === "LI").map(this.HTMLtoComponent)})
                    break;
                case "TABLE":
                    components.push({table: ([...elem.children] as HTMLElement[]).filter(firstChild => firstChild.nodeName === "TR").map(
                        row => ([...row.children] as HTMLElement[]).filter(item => item.nodeName === "TD").map(this.HTMLtoComponent)
                    )})
                    break;
                case "SPAN":
                    // Since the editing commands often introduces spans, we should behave as if
                    // they don't exist.
                    //
                    // FIXME: Any styling props of this span are ignored!
                    components.push(...this.HTMLtoComponent(elem));
                    break;
                case "DIV":
                    if (elem.classList.contains("simple-row")) {
                        components.push({row: (this.HTMLtoComponent(elem))})
                        break;
                    }
                    // fallthrough here, since we don't know what this div is
                default:
                    console.error("unknown node name: " + elem.nodeName);
                    break;
            }
        })
        return components;
    }

    parseComponent(component: HTMLElement): MText {
        const result: MText[] = [];
        component.childNodes.forEach(node => {
            if (node.nodeType === Node.TEXT_NODE)
                result.push(node.textContent || "");
            else {
                const elem = node as HTMLElement;
                let style: Record<string, string> = {};

                const type = elem.nodeName;
                switch (type) {
                    case "B":
                        style.fontWeight = "bold";
                        break;
                    case "I":
                        style.fontStyle = "italic";
                        break;
                    case "U":
                        style.textDecoration = "underline";
                        break;
                    case "S":
                        style.textDecoration = "line-through";
                        break;
                    case "SUP":
                        style.verticalAlign = "super";
                        break;
                    case "SUB":
                        style.verticalAlign = "sub";
                        break;
                    case "SPAN":
                        styleElements
                            .filter(prop => elem.style.getPropertyValue(prop))
                            .forEach(prop => style[prop] = elem.style.getPropertyValue(prop));
                        break;
                    default:
                        const computed = getComputedStyle(elem);
                        styleElements
                            .filter(prop => computed.getPropertyValue(prop))
                            .forEach(prop => style[prop] = computed.getPropertyValue(prop));
                }
                result.push({content: this.parseComponent(elem) || "", style: style})
            }
        });
        if (result.length === 1)
            return result[0];
        return result;
    }

    parse(): Doc {
        if (!this.doc || !this.renderedDiv)
            throw new Error("You cannot save a doc if you haven't opened one.")
        return {
            ...this.doc,
            components: this.HTMLtoComponent(this.renderedDiv),
        }
    }

    save() {
        document.getElementById('overlay')?.classList.add('active');
        return this.authWrapper.fetch('/submit-templated-doc', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                GroupId: this.groupId,
                DocId: this.docId,
                Doc: this.parse(),
            }),
        })
            .then(resp => {
                if (!resp.ok) throw new Error('non 200 status');
                document.getElementById('success-popup')?.classList.add('active');
            })
    }

    setVisibility(show: boolean) {
        this.templateDiv.style.display = show ? "unset" : "none";
    }

    showToolbar(ev: FocusEvent) {
        const target = ev.target as HTMLElement
        const bar = document.createElement("div");
        bar.className = "toolbar";
        const rect = target.getBoundingClientRect();
        const outerRect = this.templateDiv.getBoundingClientRect();

        this.removeToolbar();

        bar.style.left = (15 + outerRect.left).toString() + "px";
        bar.style.top = "15px";

        const boldLabel = document.createElement("b");
        boldLabel.innerText = "B";
        bar.appendChild(this.toolbarButton(() => {
            target.contentEditable = "true";
            document.execCommand("bold");
            target.contentEditable = "plaintext-only";
        },
            boldLabel))

        const underlineLabel = document.createElement("u");
        underlineLabel.innerText = "U";
        bar.appendChild(this.toolbarButton(() => {
            target.contentEditable = "true";
            document.execCommand("underline");
            target.contentEditable = "plaintext-only";
        },
            underlineLabel))

        const italicLabel = document.createElement("i");
        italicLabel.innerText = "I";
        bar.appendChild(this.toolbarButton(() => {
            target.contentEditable = "true";
            document.execCommand("italic");
            target.contentEditable = "plaintext-only";
        },
            italicLabel))

        //const strikeLabel = document.createElement("s");
        //strikeLabel.innerText = "S";
        //bar.appendChild(this.toolbarButton(() => {
        //    target.contentEditable = "true";
        //    document.execCommand("strikethrough");
        //    target.contentEditable = "plaintext-only";
        //},
        //    strikeLabel))

        const superLabel = document.createElement("span");
        superLabel.innerText = "x²";
        bar.appendChild(this.toolbarButton(() => {
            target.contentEditable = "true";
            document.execCommand("superscript");
            target.contentEditable = "plaintext-only";
        },
            superLabel))

        //const subLabel = document.createElement("sub");
        //subLabel.innerText = "x";
        //bar.appendChild(this.toolbarButton(() => {
        //    target.contentEditable = "true";
        //    document.execCommand("subscript");
        //    target.contentEditable = "plaintext-only";
        //},
        //    subLabel))

        bar.appendChild(this.toolbarButton(() => {
            const table = document.createElement("table");
            const row = document.createElement("tr");
            const cell = document.createElement("td");
            row.appendChild(cell);
            table.appendChild(row);
            this.addListeners(table);
            let node = document.getSelection()?.anchorNode;
            while (!["H1", "H2", "H3", "UL", "P", "ADDRESS"].includes(node?.nodeName!)) {
                node = node?.parentElement;
                if (!node) throw new Error("Failed to find a suitable node.")
            }
            (node as HTMLElement).after(table);
        },"table"))

        if (target.nodeName === "UL") {
            //bar.appendChild(this.toolbarButton(() => {
            //    let node = document.getSelection()?.anchorNode;
            //    while (node?.nodeName !== "LI")
            //        node = node?.parentElement;
            //    (node as HTMLElement).after(document.createElement("LI"));
            //},
            //    "+"
            //))

            //const remove = document.createElement("span");
            //remove.innerText = "X";
            //remove.style.color = "red";
            //bar.appendChild(this.toolbarButton(() => {
            //    let node = document.getSelection()?.anchorNode;
            //    while (node?.nodeName !== "LI")
            //        node = node?.parentElement;
            //    (node as HTMLElement)?.remove();
            //},
            //    remove
            //))
        }
        else {
            bar.appendChild(this.toolbarButton(() => this.changeElement("h1"), "h1"))
            bar.appendChild(this.toolbarButton(() => this.changeElement("h2"), "h2"))
            bar.appendChild(this.toolbarButton(() => this.changeElement("h3"), "h3"))
            bar.appendChild(this.toolbarButton(() => this.changeElement("p"), "p"))
        }

        bar.appendChild(this.toolbarButton(() => {
            const node = target as HTMLElement;
            if (node.nodeName !== "UL") {
                node.contentEditable = "false";
                const parent = node.parentElement;
                const after = node?.nextSibling;
                const list = document.createElement("ul");
                this.addListeners(list);
                const item = document.createElement("li");
                list.appendChild(item);
                item.appendChild(this.changeElement("P"));
                parent!.insertBefore(list, after)
                list.focus();
            } else {
                [...(node as HTMLUListElement).children]
                    // Consider the children of each list item.
                    .flatMap(li => [...li.children])
                    // :D
                    .reverse()
                    .forEach(child => {
                        if (child.nodeType === Node.TEXT_NODE) {
                            const para = document.createElement("p");
                            this.addListeners(para);
                            para.innerHTML = (child as HTMLElement).innerHTML!;
                            node.after(para);
                            para.focus();
                        } else if (child.nodeType === Node.ELEMENT_NODE) {
                            this.addListeners(child as HTMLElement);
                            node.after(child);
                            (child as HTMLElement).focus();
                        }
                    });
                node.remove();
            }
        }, "⋮☰"))

        bar.appendChild(this.toolbarButton(() => {
            target.remove();
            this.removeHoverButtons();
            this.removeToolbar();
        }, "🗑️"))

        bar.addEventListener("mousedown", ev => ev.preventDefault());
        bar.addEventListener("mouseup", ev => ev.preventDefault());
        bar.addEventListener("click", ev => ev.preventDefault());

        this.templateDiv.appendChild(bar);
        this.bar = true;
    }

    hoverButtons(target: HTMLElement) {
        this.removeHoverButtons();

        function bc(labelText: string, position: string): Node[] {
            const plus = document.createTextNode("+");
            const label = document.createElement("div");
            label.classList.add("hover-label");
            label.classList.add("hover-label-" + position);
            label.innerText = labelText;
            return [plus, label];
        }

        const padding = 8;

        const rect = target.getBoundingClientRect();
        const outerRect = this.templateDiv.getBoundingClientRect();
        //directional buttons
        const down = this.toolbarButton(() => {
            let node = target;
            while (node.parentElement && !node.parentElement?.classList.contains("templated-doc"))
                node = node.parentElement;
            if (!node.parentElement) throw new Error("Failed to find parent.");
            const para = document.createElement("P");
            this.addListeners(para);
            (node as HTMLElement).after(para);
            para.focus();
        }, bc("row", "below"))
        down.classList.add("hover-button");
        down.style.position = "absolute";
        down.style.transform = "translateX(-50%)";
        down.style.top = (rect.bottom - outerRect.top + padding).toString() + "px";
        down.style.left = (rect.left - outerRect.left + rect.width / 2).toString() + "px";
        this.templateDiv.appendChild(down);

        const up = this.toolbarButton(() => {
            let node = target;
            while (node.parentElement && !node.parentElement?.classList.contains("templated-doc"))
                node = node.parentElement;
            if (!node.parentElement) throw new Error("Failed to find parent.");
            const para = document.createElement("P");
            this.addListeners(para);
            (node as HTMLElement).before(para);
            para.focus();
        }, bc("row", "above"))
        up.classList.add("hover-button");
        up.style.position = "absolute";
        up.style.transform = "translateX(-50%) translateY(-100%)";
        up.style.top = (rect.top - outerRect.top - padding).toString() + "px";
        up.style.left = (rect.left - outerRect.left + rect.width / 2).toString() + "px";
        this.templateDiv.appendChild(up);

        const right = this.toolbarButton(() => {
            const node = target
            let parent: HTMLElement | null = node;
            while (parent && !(parent as HTMLElement).classList.contains("simple-row"))
                parent = parent.parentElement;

            const para = document.createElement("P");
            this.addListeners(para);

            if (parent) {
                (node as HTMLElement).after(para);
                para.focus();
            }
            else {
                parent = node!.parentElement!;
                const row = document.createElement("div");
                row.className = "simple-row";
                const after = node?.nextSibling;
                node?.remove();
                row.appendChild(node as HTMLElement);
                row.appendChild(para);
                parent.appendChild(row);
                parent.insertBefore(row, after || null);
                para.focus();
            }
        }, bc("column", "right"));
        right.classList.add("hover-button");
        right.style.position = "absolute";
        right.style.transform = "translateY(-50%)";
        right.style.top = (rect.top - outerRect.top + rect.height / 2).toString() + "px";
        right.style.left = (rect.right - outerRect.left + padding).toString() + "px";
        this.templateDiv.appendChild(right);

        const left = this.toolbarButton(() => {
            const node = target
            let parent: HTMLElement | null = node;
            while (parent && !(parent as HTMLElement).classList.contains("simple-row"))
                parent = parent.parentElement;

            const para = document.createElement("P");
            this.addListeners(para);

            if (parent) {
                (node as HTMLElement).before(para);
                para.focus();
            }
            else {
                parent = node!.parentElement!;
                const row = document.createElement("div");
                row.className = "simple-row";
                const after = node?.nextSibling;
                node?.remove();
                row.appendChild(para);
                row.appendChild(node as HTMLElement);
                parent.appendChild(row);
                parent.insertBefore(row, after || null);
                para.focus();
            }
        }, bc("column", "left"));
        left.classList.add("hover-button");
        left.style.position = "absolute";
        left.style.transform = "translateX(-100%) translateY(-50%)";
        left.style.top = (rect.top - outerRect.top + rect.height / 2).toString() + "px";
        left.style.left = (rect.left - outerRect.left - padding).toString() + "px";
        this.templateDiv.appendChild(left);
    }

    removeHoverButtons() {
        this.templateDiv.querySelectorAll(".hover-button").forEach(button => button.remove());
    }

    toolbarButton(onClick: () => void, label?: string | HTMLElement | Node[], img?: string) {
        const button = document.createElement("button") as HTMLButtonElement;
        button.className = "toolbar-button";
        button.type = "button";
        button.onclick = (ev) => {ev.preventDefault(); onClick()};
        if (typeof label === "string") button.innerText = label;
        else if (label && "length" in label) button.append(...label);
        else if (label) button.appendChild(label);
        if (img) {
            const image = document.createElement("img") as HTMLImageElement;
            image.src = img;
            button.appendChild(image);
        }

        button.addEventListener("mousedown", ev => ev.preventDefault());
        button.addEventListener("mouseup", ev => ev.preventDefault());
        return button;
    }

    removeToolbar() {
        if (this.bar)
            this.templateDiv.querySelector(".toolbar")?.remove();
    }

    keyHandler(ev: KeyboardEvent) {
        const elem = ev.target as HTMLElement;
        let component: HTMLElement | null = elem;
        while (!["H1", "H2", "H3", "UL", "P", "ADDRESS", "TABLE"].includes(elem?.nodeName!)) {
            component = elem?.parentElement;
            if (!component) throw new Error("Failed to find a suitable node.")
        }
        requestAnimationFrame(() => component && this.hoverButtons(component));

        if (ev.key === "Enter" && elem.nodeName === "UL") {
            ev.preventDefault();
            const selection = document.getSelection()!;
            const caretPosition = selection?.anchorOffset;
            const anchorNode = selection.anchorNode!;
            const content = [anchorNode.textContent?.substring(0, caretPosition), anchorNode.textContent?.substring(caretPosition)];
            anchorNode.textContent = content[0]!;

            const newItem = document.createElement("li");
            (anchorNode as HTMLElement).parentElement!.after(newItem);
            (newItem as HTMLElement).innerText = content[1]!;
            document.getSelection()?.modify("move", "forward", "line");
        }

        if (ev.key === "Backspace" && elem.nodeName === "UL" && document.getSelection()?.anchorOffset === 0) {
            ev.preventDefault();
            const elem = document.getSelection()?.anchorNode as HTMLElement;
            document.getSelection()?.modify("move", "backward", "character");
            elem.remove();
        }
    }

    changeElement(newType: string) {
        const selection = document.getSelection();
        let node = selection?.anchorNode;
        while (!["H1", "H2", "H3", "UL", "P", "ADDRESS"].includes(node?.nodeName!)) {
            node = node?.parentElement;
            if (!node) throw new Error("Failed to find a suitable node.")
        }
        const replacement = document.createElement(newType);
        replacement.innerHTML = (node as HTMLElement).innerHTML;
        this.addListeners(replacement);

        (node as HTMLElement).replaceWith(replacement);
        replacement.focus();

        return replacement;
    }

    contextMenu(ev: MouseEvent) {
        this.templateDiv.querySelectorAll("menu").forEach(menu=>menu.remove());
        if (ev.button !== 2) return;
        ev.preventDefault();

        let cell : Node|null = ev.target as Node;
        while (cell && cell.nodeName !== "TD")
            cell = cell?.parentElement;
        let row = cell?.parentElement;
        while (row && row.nodeName !== "TR")
            row = row?.parentElement;
        let table = row?.parentElement;
        while (table && table.nodeName !== "TABLE")
            table = table?.parentElement;
        if (!(cell && row && table)) return;

        const colCount = ([...(row as HTMLElement).children] as HTMLElement[]).filter(item => item.nodeName === "TD").length;

        const menu = document.createElement("menu");
        menu.style.top = ev.clientY.toString() + "px";
        menu.style.left = ev.clientX.toString() + "px";
        this.templateDiv.append(menu);

        const above = document.createElement("button");
        above.setAttribute("type","button");
        above.innerText = "Insert row above"
        above.onclick = (()=>{
            const newRow = document.createElement("tr");
            for (let i = 0; i < colCount; i++)
                newRow.append(document.createElement("td"));
            (row as HTMLElement).before(newRow);
            menu.remove();
        })
        menu.append(above);

        const below = document.createElement("button");
        below.setAttribute("type","button");
        below.innerText = "Insert row below"
        below.onclick = (()=>{
            const newRow = document.createElement("tr");
            for (let i = 0; i < colCount; i++)
                newRow.append(document.createElement("td"));
            (row as HTMLElement).after(newRow);
            menu.remove();
        })
        menu.append(below);

        const left = document.createElement("button");
        left.setAttribute("type","button");
        left.innerText = "Insert column left"
        left.onclick = (()=>{
            const cells = [...row.children] as HTMLElement[];
            let col = 0;
            while (cells[col] !== cell)
                col++;

            const rows = ([...table.children] as HTMLElement[]).filter(row => row.nodeName === "TR");
            rows.forEach(row => ([...row.children] as HTMLElement[])[col].before(document.createElement("td")));
            menu.remove();
        })
        menu.append(left);

        const right = document.createElement("button");
        right.setAttribute("type","button");
        right.innerText = "Insert column right"
        right.onclick = (()=>{
            const cells = [...row.children] as HTMLElement[];
            let col = 0;
            while (cells[col] !== cell)
                col++;

            const rows = ([...table.children] as HTMLElement[]).filter(row => row.nodeName === "TR");
            rows.forEach(row => ([...row.children] as HTMLElement[])[col].after(document.createElement("td")));
            menu.remove();
        })
        menu.append(right);

        const deleteRow = document.createElement("button");
        deleteRow.setAttribute("type","button");
        deleteRow.innerText = "Delete row";
        deleteRow.onclick = (() => {
            row.remove();
            menu.remove();
        });
        menu.append(deleteRow);

        const deleteCol = document.createElement("button");
        deleteCol.setAttribute("type","button");
        deleteCol.innerText = "Delete column";
        deleteCol.onclick = (() => {
            const cells = [...row.children] as HTMLElement[];
            let col = 0;
            while (cells[col] !== cell)
                col++;

            const rows = ([...table.children] as HTMLElement[]).filter(row => row.nodeName === "TR");
            if ([...rows[0].children].length === 1) return;
            rows.forEach(row => ([...row.children] as HTMLElement[])[col].remove());
            menu.remove();
        });
        menu.append(deleteCol);
    }
}
