import {Page} from './Page';
import {Box, SelectedBox} from './Box';
import {Application, HighlightData, HighlightBox} from './Editor';
import { AsyncAuthWrapper } from '../frontend-auth/auth-wrapper';

function sum(arr: number[]): number {
    return arr.reduce((a, b) => a + b, 0);
}

export class Display {
  private canvas: HTMLCanvasElement;
  private ctx: CanvasRenderingContext2D;
  private width: number;
  private height: number;
  private pages: Page[];
  private selectedBox: SelectedBox | null;
  private showUnredacted: boolean;
  private groupId?: string;
  private docId?: string;
  private authWrapper: AsyncAuthWrapper;

  constructor(authWrapper: AsyncAuthWrapper, canvas: HTMLCanvasElement) {
    this.authWrapper = authWrapper;
    canvas.width = 0;
    canvas.height = 0;
    this.canvas = canvas;
    this.ctx = canvas.getContext('2d')!;
    this.width = 0;
    this.height = 0;
    this.pages = [];
    this.selectedBox = null;
    this.draw = this.draw.bind(this);
    this.deselectBox = this.deselectBox.bind(this);
    this.newBox = this.newBox.bind(this);
    this.save = this.save.bind(this);
    this.submit = this.submit.bind(this);
    this.click = this.click.bind(this);
    this.mousedown = this.mousedown.bind(this);
    this.mousemove = this.mousemove.bind(this);
    this.mouseup = this.mouseup.bind(this);
    this.loadDocument = this.loadDocument.bind(this);
    this.showUnredacted = false;

    canvas.addEventListener('click', this.click);
    canvas.addEventListener('mousedown', this.mousedown);
    canvas.addEventListener('mousemove', this.mousemove);
    canvas.addEventListener('mouseup', this.mouseup);
  }

  setVisiblity(show: boolean) {
    this.canvas.style.display = show ? "unset" : "none"
  }

  enableUnredacted() {
    this.showUnredacted = true;
    this.canvas.width = this.width * 2;
    this.canvas.style.maxWidth = '2000px';
  }

  disableUnredacted() {
    this.showUnredacted = false;
    this.canvas.width = this.width;
    this.canvas.style.maxWidth = '1000px';
  }

  toggleUnredacted() {
      if (this.showUnredacted) this.disableUnredacted();
      else this.enableUnredacted();
  }

  isShowingUnredacted(): boolean {
      return this.showUnredacted;
  }

  draw(): void {
    if (this.showUnredacted) {
      this.ctx.clearRect(0, 0, this.width*2, this.height);
      this.ctx.setTransform(1, 0, 0, 1, this.width, 0)
      this.pages.forEach(page => page.draw(this.ctx, false));
      this.ctx.setTransform(1, 0, 0, 1, 0, 0)
    } else {
      this.ctx.clearRect(0, 0, this.width, this.height);
    }
    this.pages.forEach(page => page.draw(this.ctx));
    if (this.selectedBox) {
      this.selectedBox.draw(this.ctx, this.pages);
    }
  }

  newBox(): void {
    this.deselectBox();
    const displayedSize = this.ctx.canvas.getBoundingClientRect();
    const scaleY = Number(this.ctx.canvas.height) / displayedSize.height;
    let top = Math.min(this.width / 4, this.height / 3) + scrollY * scaleY;
    const page = this.pages.length-1;
    const yOffset = this.pages[page].top;
    top -= yOffset;

    this.selectedBox = new SelectedBox(
      page,
      new Box(
        {
          top: top,
          bottom: top + 20,
          left: this.width / 2 - 25,
          right: this.width / 2 + 25,
        },
        'black'
      )
    );
    this.draw();
  }

  deselectBox() {
    if (this.selectedBox) {
      this.pages[this.selectedBox.pageIdx].addBox(this.selectedBox.box);
      this.selectedBox = null;
    }
  }

  async submit(): Promise<void> {
    console.log("Submitting!");
    document.getElementById('overlay')?.classList.add('active');
    this.deselectBox();
    this.draw();
    const boxes: HighlightBox[][] = this.pages.map(_ => []);
    // Boxes can be dragged between pages, so we can't assume they're on the correct page
    this.pages
        .flatMap(page => page.boxes.map(box => [page.top, box] as [number, Box]))
        .forEach(([yOffset, box]) => {
            this.pages.map((page, idx): [Page, number] => [page, idx]).filter(
                // Take only the page that contains this box
                ([page, idx]) => {
                    console.log(idx, page, box.bounds);
                    return page.top <= yOffset + box.bounds.top && yOffset + box.bounds.bottom <= page.top + page.height;
                }
            ).forEach(([page, idx]) => {
                boxes[idx].push({
                    Bounds: {
                        left: box.bounds.left / page.scale,
                        right: box.bounds.right / page.scale,
                        top: (page.height - (yOffset + box.bounds.top) + page.top) / page.scale,
                        bottom: (page.height - (yOffset + box.bounds.bottom) + page.top) / page.scale,
                    },
                    IsImage: false,
                    HexCode: box.color || "#000000",
                });
            });
        });
    console.log(boxes);
    const resp = await this.authWrapper.fetch('/submit', {
      method: 'POST',
      headers: {
          "Content-Type": "application/json",
      },
      body: JSON.stringify({
          GroupId: this.groupId,
          DocId: this.docId,
          Boxes: boxes,
      }),
    })
    if (!resp.ok) {
        alert('An error occured while submitting the document. Please try again. If this persists, please contact support.');
        throw new Error("Submit response not OK");
    }
    document.getElementById('success-popup')?.classList.add('active');
    return;
  }

  save() {
    this.deselectBox();
    this.draw();
    const originalCanvas = document.getElementById(
      'display'
    ) as HTMLCanvasElement;
    const canvasWidth = originalCanvas.width;
    const canvasHeight = originalCanvas.height;
    const numSections = this.pages.length;
    const imageDatas : Array<string> = [];

    const sectionContainer = document.createElement('div');
    sectionContainer.id = 'sectionContainer';
    document.body.appendChild(sectionContainer);
    sectionContainer.style.display = 'none';

    const downloadContainer = document.createElement('div');
    downloadContainer.id = 'downloadContainer';
    document.body.appendChild(downloadContainer);

    const pagesW: number[] = [];
    const pagesH: number[] = [];

    for (let section = 0; section < numSections; section++) {
      const sectionCanvas = document.createElement('canvas');
      sectionCanvas.width = canvasWidth;
      sectionCanvas.height = Math.min(
        this.pages[section].height,
        canvasHeight - section * this.pages[section].height
      );
      pagesH.push(sectionCanvas.height);
      pagesW.push(sectionCanvas.width);
      const sectionContext = sectionCanvas.getContext('2d');

      const startY = section * this.pages[section].height;
      sectionContext!.drawImage(
        originalCanvas,
        0,
        startY,
        canvasWidth,
        sectionCanvas.height,
        0,
        0,
        canvasWidth,
        sectionCanvas.height
      );

      sectionContainer.appendChild(sectionCanvas);

      const imgData = sectionCanvas.toDataURL();
      imageDatas.push(imgData);
      //downloadContainer.appendChild(downloadLink);
    }

    const formData = new FormData();

    imageDatas.forEach(imageData => {
      const blob = new Blob([imageData], {type: 'image/png'});
      formData.append('images', blob, 'image.png');
    });
    for (const height of pagesH) {
      formData.append('pagesH', String(height));
    }
    for (const width of pagesW) {
      formData.append('pagesW', String(width));
    }
    this.authWrapper.fetch('/set-images', {
      method: 'POST',
      body: formData,
    })
      .then(response => {
        console.log(response);
        return response.blob();
        // Handle the response
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'output.pdf';
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }

  getEventCoords(ev: MouseEvent): {x: number; y: number} {
    const target = ev.target as HTMLCanvasElement;
    const displayedSize = target.getBoundingClientRect();
    const scaleX = Number(target.width) / displayedSize.width;
    const scaleY = Number(target.height) / displayedSize.height;
    //const xCorrection = this.showUnredacted?this.width:0;
    const xCorrection = 0;
    return {
      x: ev.offsetX * scaleX - xCorrection,
      y: ev.offsetY * scaleY,
    };
  }

  mousedown(ev: MouseEvent, clicked = false): void {
    const {x, y} = this.getEventCoords(ev);
    if (
      (!this.selectedBox || !this.selectedBox.mousedown(x, y, this.pages)) &&
      !clicked
    ) {
      this.click(ev, false);
      return this.mousedown(ev, true);
    }
    this.draw();
  }

  mousemove(ev: MouseEvent): void {
    if (this.selectedBox) {
      const {x, y} = this.getEventCoords(ev);
      this.selectedBox.mousemove(x, y);
      this.draw();
    }
  }

  mouseup(ev: MouseEvent): void {
    if (this.selectedBox) {
      const {x, y} = this.getEventCoords(ev);
      this.selectedBox.mouseup(x, y);
      this.draw();
    }
  }

  click(ev: MouseEvent, draw = true) {
    const {x, y} = this.getEventCoords(ev);

    if (this.selectedBox) {
      if (this.selectedBox.isDelete(this.pages, x, y)) {
        this.selectedBox = null;
        this.draw();
        return;
      }
      if (this.selectedBox.contains(this.pages, x, y)) return;
      this.deselectBox();
    }

    for (let pageIdx = 0; pageIdx < this.pages.length; pageIdx++) {
      const box = this.pages[pageIdx].takeBox(x, y);
      if (box) {
        this.selectedBox = new SelectedBox(pageIdx, box);
        break;
      }
    }

    if (draw) this.draw();
  }

  async getItem(
      groupId: string,
      docId: string,
  ): Promise<HighlightData[]> {
    // TODO: This only returns the first page of the document.
    // We should obviously allow the user to select a document.
    // Furthermore, we should show the user the document info!!
    console.log("Getting", groupId, docId);
    return this.authWrapper.fetch('/get-group-highlight-data', {
      method: 'POST',
      body: groupId,
    })
      .then(resp => {
        if (!resp.ok) throw new Error('non 200 status');
        return resp.json() as Promise<Application>;
      })
      .then(resp => resp.Docs[docId].HighlightDatas);
  }

  async getImage(name: string): Promise<Blob> {
    return this.authWrapper.fetch('/get-img', {
      method: 'POST',
      body: name,
    }).then(resp => {
      if (!resp.ok) throw new Error('non 200 status');
      return resp.blob();
    });
  }

  async loadDocument(
      groupId: string,
      docId: string,
  ): Promise<void> {
    this.selectedBox = null;

    // Get the document info
    const pages = await this.getItem(groupId, docId);
    console.log(groupId);
    console.log(docId);
    console.log(pages);
    const pageInfo = pages.map(page => page.Page);

    // Start a request in the background to get all the images
    const imagesPromise = Promise.all(pageInfo.map(page =>
      this.getImage(page.ImageBlobName).then(createImageBitmap)
    ));

    // Determine the document dimensions
    const pageWidths = pageInfo.map(page => page.Width * page.Scale);
    const pageHeights = pageInfo.map(page => page.Height * page.Scale);

    // Generate the pages
    this.pages = (await imagesPromise).map((bitmap, idx) => {
      const scale = pageInfo[idx].Scale;
      const top = sum(pageHeights.slice(0, idx));
      const height = pageHeights[idx];

      function isWithin(box1: Box, box2: Box): boolean {
        return (
          box1.bounds.left >= box2.bounds.left &&
          box1.bounds.right <= box2.bounds.right &&
          box1.bounds.top >= box2.bounds.top &&
          box1.bounds.bottom <= box2.bounds.bottom
        );
      }
      function calculateOverlapPercentage(box1: Box, box2: Box) {
        const intersectionLeft = Math.max(box1.bounds.left, box2.bounds.left);
        const intersectionRight = Math.min(
          box1.bounds.right,
          box2.bounds.right
        );
        const intersectionTop = Math.max(box1.bounds.top, box2.bounds.top);
        const intersectionBottom = Math.min(
          box1.bounds.bottom,
          box2.bounds.bottom
        );

        const intersectionWidth = intersectionRight - intersectionLeft;
        const intersectionHeight = intersectionBottom - intersectionTop;

        if (intersectionWidth < 0 || intersectionHeight < 0) {
          return 0;
        }

        const intersectionArea = intersectionWidth * intersectionHeight;
        const box1Area =
          (box1.bounds.right - box1.bounds.left) *
          (box1.bounds.bottom - box1.bounds.top);

        const overlapPercentage = (intersectionArea / box1Area) * 100;
        return overlapPercentage;
      }

      function mergeBoxes(box1: Box, box2: Box) {
        const mergedBox = {
          bounds: {
            left: Math.min(box1.bounds.left, box2.bounds.left),
            right: Math.max(box1.bounds.right, box2.bounds.right),
            top: Math.min(box1.bounds.top, box2.bounds.top),
            bottom: Math.max(box1.bounds.bottom, box2.bounds.bottom),
          },
        };

        return mergedBox;
      }

      const boxes: Box[] = [];
      pages[idx].HighlightBoxes.forEach(
        (box: {
          Bounds: {left: number; right: number; top: number; bottom: number};
          HexCode: string;
        }) => {
          const bounds = box.Bounds;
          bounds.left *= scale;
          bounds.right *= scale;
          bounds.top = height - bounds.top * scale;
          bounds.bottom = height - bounds.bottom * scale;

          let nbox = new Box(bounds, box.HexCode);
          let found = false;
          for (let i = 0; i < boxes.length; i++) {
            if (isWithin(nbox, boxes[i])) {
              found = true;
              break;
            } else if (isWithin(boxes[i], nbox)) {
              boxes.splice(i, 1);
              i--;
            }
          }

          if (!found) {
            for (let i = 0; i < boxes.length; i++) {
              if (calculateOverlapPercentage(nbox, boxes[i]) > 0) {
                nbox = new Box(mergeBoxes(boxes[i], nbox).bounds, box.HexCode);
                break;
              }
            }
            boxes.push(nbox);
          }
        }
      );

      for (let i1 = 0; i1 < boxes.length; i1++) {
        const nbox = boxes[i1];
        for (let i = 0; i < boxes.length; i++) {
          if (isWithin(nbox, boxes[i])) {
            break;
          } else if (isWithin(boxes[i], nbox)) {
            boxes.splice(i, 1);
            i--;
          }
        }
      }

      return new Page(
        scale,
        top,
        height,
        // Map the boxes from PDF coordinates to pixel coordinates
        boxes,
        bitmap
      );
    });

    this.width = Math.max(...pageWidths);
    this.height = sum(pageHeights);
    // Add enough padding for a delete button right at the bottom
    this.canvas.height = this.height + 35;
    // This updates the width
    this.disableUnredacted();
    // TODO: draw loading indicator?
    this.groupId = groupId;
    this.docId = docId;

    this.draw();
  }
}
