import {AsyncAuthWrapper} from './frontend-auth/auth-wrapper';
import {parseSearchArgs} from './frontend-auth/handover-token';

import {Editor} from './components/Editor';

interface Filters {
  CandidateId: string;
  JobTitle: string;
  CandidateName: string;
  JobId: string;
}

const filters: Filters = {
  CandidateId: '',
  JobTitle: '',
  CandidateName: '',
  JobId: '',
};

function sleep(duration: number): Promise<void> {
    return new Promise(resolve => setTimeout(() => resolve(), duration));
}

async function hidePageQuick() {
    document.querySelector('main')!.classList.add('hidden');
}

async function showPage() {
    document.querySelector('main')!.classList.remove('hidden');
    document.querySelector('main')!.classList.remove('hidden-smooth');
}

async function hideSidebar() {
    document.querySelector('main')!.classList.add('hidden-smooth');
    document.querySelector('#small-menu')!.classList.add('hidden-smooth');
    await sleep(320);
    document.querySelector('main')!.style.paddingLeft = '50px';
    document.querySelector('main')!.style.paddingRight = '50px';
    document.querySelector('#close-menu-button')!.classList.add('hidden');
    document.querySelector('#recent-docs')!.classList.add('hidden');
    document.querySelector('main')!.classList.remove('hidden');
    document.querySelector('main')!.classList.remove('hidden-smooth');
    document.querySelector('#open-menu-button')!.classList.remove('hidden');
    document.querySelector('#small-menu')!.classList.remove('hidden-smooth');
}

async function openSidebar() {
    document.querySelector('main')!.classList.add('hidden-smooth');
    document.querySelector('#small-menu')!.classList.add('hidden-smooth');
    await sleep(320);
    document.querySelector('main')!.style.paddingLeft = 'unset';
    document.querySelector('main')!.style.paddingRight = 'unset';
    document.querySelector('#open-menu-button')!.classList.add('hidden');
    document.querySelector('#recent-docs')!.classList.remove('hidden');
    document.querySelector('main')!.classList.remove('hidden');
    document.querySelector('main')!.classList.remove('hidden-smooth');
    document.querySelector('#close-menu-button')!.classList.remove('hidden');
    document.querySelector('#small-menu')!.classList.remove('hidden-smooth');
}

document.getElementById('close-menu-button')?.addEventListener('click', hideSidebar);
document.getElementById('open-menu-button')?.addEventListener('click', openSidebar);

document.querySelectorAll('.close-overlay')
    .forEach(elem => elem.addEventListener('click', () => {
        document.getElementById('overlay')?.classList.remove('active');
    }));

const editor = new Editor(
  AsyncAuthWrapper.fromSession(() => location.href = "https://accounts.mevitae.com"),
  document.getElementById('display')! as HTMLCanvasElement,
  document.getElementById('embed-pdf')! as HTMLEmbedElement,
  document.getElementById('templating-display')! as HTMLDivElement,
  document.getElementById('new-redaction')!,
  document.getElementById('unredacted-button')!,
  document.getElementById('redacted-button')!,
  document.getElementById('download-button')!,
  document.getElementById('toolbar')!,
  document.getElementById("new-Candidates")!,
  document.getElementById("refresh-button")!,
);

editor.updateList(filters);

//editor.editTemplatedDoc({"theme":"welocalize",
//"components":[{"addressRight":"Global Headquaters\n241 East 4th Street Suite 207\nFrederick, Maryland 2170\n301.668.0330 | 800.370.9515\nwelocalize.com"},
//{"h1":"Linguist name/initials"},{"h3":"Languages"},{"h2":"Professional Experience"},{"row":[{"h3":"Job title, company name"},{"p":"Month Year - Month Year"}]},
//{"ul":[[{"p":"The candiates narrative goes here..."}],[{"p":"With multiple bullet points..."}],[{"p":"Hopefully we parse them okay!..."}]]},
//{"row":[{"h3":"Job title, company name"},{"p":"Month Year - Month Year"}]},{"ul":[[{"p":"The candiates narrative goes here..."}],
//[{"p":"With multiple bullet points..."}],[{"p":"Hopefully we parse them okay!..."}]]},{"h2":"Education Background"},
//{"p":"Here goes their, unedited, education section."}],
//"page_width":8.27,"page_height":11.69,"page_margin_top":1.0,"page_margin_bottom":1.0,"page_margin_left":1.0,"page_margin_right":1.0})

function filterInputUpdate(ev: Event) {
    const target = ev.target as HTMLInputElement;
    const filter = target.getAttribute('x-filter');
    filters[filter!] = target.value;
    editor.updateList(filters);
}

document.querySelectorAll('#filters > input').forEach(elem => {
    const input = elem as HTMLInputElement;
    input.addEventListener('change', filterInputUpdate);
    input.addEventListener('keypress', filterInputUpdate);
    input.addEventListener('keyup', filterInputUpdate);
});

/**
 * Convert a string to upper cammel case. For example:
 *
 * alreadyUpperCamel -> AlreadyUpperCamel
 * lowerCammelCase -> LowerCammelCase
 * snake_case -> SnakeCase
 * With some spaces -> WithSomeSpaces
 * Part of LGBTQ -> PartOfLGBTQ
 */
function toUpperCammelCase(s: string): string {
    // First, remove surrounding whitespace
    s = s.trim();
    // The output starts with the first character, lower cased
    let output = s[0].toUpperCase();
    // Then we iterate over the remaining characters
    let isFollowingSpace = false;
    for (const ch of s.substring(1)) {
        switch (ch) {
            case '_':
            case '-':
            case ' ':
            case '\t':
            case '\r':
            case '\n':
                isFollowingSpace = true;
                break;
            default:
                if (isFollowingSpace) {
                    output += ch.toUpperCase();
                    isFollowingSpace = false;
                } else
                    output += ch;
        }
    }
    return output;
}

function testToUpperCammelCase() {
    // alreadyUpperCamel -> alreadyUpperCamel
    console.assert(toUpperCammelCase("AlreadyUpperCamel") === "AlreadyUpperCamel");
    // UpperCammelCase -> upperCammelCase
    console.assert(toUpperCammelCase("lowerCammelCase") === "LowerCammelCase");
    // snake_case -> snakeCase
    console.assert(toUpperCammelCase("snake_case") === "SnakeCase");
    // With some spaces -> withSomeSpaces
    console.assert(toUpperCammelCase("With some spaces") === "WithSomeSpaces");
    // Part of LGBTQ -> partOfLGBTQ
    console.assert(toUpperCammelCase("Part of LGBTQ") === "PartOfLGBTQ");
}

const args = parseSearchArgs();
for (const key in args) {
    const filter = toUpperCammelCase(key);
    for (const input of document.querySelectorAll('#filters > input')) {
        if (input.getAttribute('x-filter') === filter) {
            const value = args[key];
            (input as HTMLInputElement).value = value;
            filters[filter] = value;
            break;
        }
    }
}
editor.updateList(filters);
if (args.job_id && args.candidate_id && args.doc_type && !isNaN(Number(args.doc_type))) {
    document.querySelector('#small-menu')!.classList.add('hidden-smooth');
    document.querySelector('#small-menu')!.classList.add('hidden');
    editor.autoselect(args.job_id, args.candidate_id, Number(args.doc_type), args.job || "a")
        .then(async selected => {
            // If selected, give is 500ms to load before fading in (and time for the hidden buttons to fade out)
            if (selected) await sleep(500)
            document.querySelector('#small-menu')!.classList.remove('hidden');
            if (selected) hideSidebar();
            else openSidebar();
        });
} else {
    showPage();
}
