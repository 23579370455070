import * as fuzzysort from 'fuzzysort';

import {AsyncAuthWrapper} from '../frontend-auth/auth-wrapper';
import {Display} from './Display';
import { Templating } from './Templating';
import { Doc } from '../template-render-html/component';

interface Candidate {
  DomainId: string;
  IntegrationSettingsId: number;
  GroupId: string;
  JobId: string;
  JobTitle: string;
  CandidateId: string;
  FirstName: string;
  LastName: string;
  CandidateName: string;

  DocumentIds: string[];
  DocumentTypes: number[];
  DocumentJobs: string[];

  /**
   * If computed, the filter match score.
   */
  score?: number;
}

interface CandidateSnapshot {
  Candidates: Candidate[];
  Timestamp: string;
}

/**
 * Return a promise that resolves after `duration` milliseconds.
 */
function sleep(duration: number): Promise<void> {
    return new Promise(resolve => setTimeout(() => resolve(), duration));
}

/**
 * Returns true iff `xs` and `ys` have the same elements (in any order).
 */
function setsEqual(xs: string[], ys: string[]): boolean {
  for (const x of xs)
    if (!ys.includes(x)) return false;
  for (const y of ys)
    if (!xs.includes(y)) return false;
  return true;
}

/**
 * Returns true iff `a` and `b` have the same keys, with the same values.
 */
function recordsEqual(a: Record<string, string | number>, b: Record<string, string | number>): boolean {
  const aKeys = Object.keys(a);
  // The objects must have the same keys
  if (!setsEqual(aKeys, Object.keys(b))) return false;

  // And, if they do have the same keys, each value must be equal.
  for (const key of aKeys)
    if (a[key] !== b[key]) return false;

  return true;
}

export type Application = {
    Docs: Record<string, DocumentBoxData>,
};

export type DocumentBoxData = {
    HighlightDatas: HighlightData[],
    DocumentType: number,
};

export type HighlightData = {
    HighlightBoxes: HighlightBox[],
    Page: PageInfo,
};

export type PageInfo = {
    Width: number,
    Height: number,
    Scale: number,
    ImageBlobName: string,
};

export type HighlightBox = {
    Bounds: HighlightBoxBounds,
    IsImage: boolean,
    HexCode: string,
};

export type HighlightBoxBounds = {
    left: number,
    right: number,
    top: number,
    bottom: number,
};

function testRecordsEqual() {
    const a = {a:1};
    const b = {b:2};
    const b3 = {b:3};
    const ab3 = {a:1,b:3};
    const ab32 = {a:1,b:3};
    const ab5 = {a:1,b:5};
    const ab2 = {a:2,b:3};
    const anotherA = {a:1};
    const anotherB3 = {b:3};
    console.assert(recordsEqual(a, anotherA));
    console.assert(!recordsEqual(a, b));
    console.assert(!recordsEqual(a, b3));
    console.assert(recordsEqual(anotherB3, b3));
    console.assert(!recordsEqual(b, b3));
    console.assert(recordsEqual(ab3, ab32));
    console.assert(!recordsEqual(ab3, a));
    console.assert(!recordsEqual(ab3, b3));
    console.assert(!recordsEqual(ab3, b));
    console.assert(!recordsEqual(ab3, ab2));
    console.assert(!recordsEqual(ab3, ab5));
}

export class Editor {
  private display: Display;
  private templateDisplay: Templating;
  private toolbar: HTMLElement | null;
  private newRedactionButton: HTMLElement | null;
  private newCandidatesButton: HTMLElement | null;
  private redactedButton: HTMLElement | null;
  private unredactedButton: HTMLElement | null;
  private downloadButton: HTMLElement | null;
  private refreshButton: HTMLElement | null;
  private localList: Promise<CandidateSnapshot>;
  private webSocket: WebSocket;
  private newCandidates: Candidate[];
  private filters: any;
  private currentJob?: string;
  private DocumentType = {
    "0": 'None',
    "1": 'CV',
    "2": 'Application Form',
    "3": 'Cover Letter',
    "4": 'Statement Of Work',
    "10": 'Other',
  };
  private authWrapper: AsyncAuthWrapper;

  constructor(
    authWrapper: AsyncAuthWrapper,
    canvas: HTMLCanvasElement,
    embedPdf: HTMLEmbedElement,
    templateDisplay: HTMLDivElement,
    newRedactionButton: HTMLElement,
    unredactedButton: HTMLElement,
    redactedButton: HTMLElement,
    downloadButton: HTMLElement,
    toolbar: HTMLElement,
    newCandidatesButton:HTMLElement,
    refreshButton:HTMLElement,
  ) {
    this.authWrapper = authWrapper;
    this.display = new Display(authWrapper, canvas);
    this.templateDisplay = new Templating(authWrapper, embedPdf, templateDisplay);
    this.toolbar = toolbar;
    this.newRedactionButton = newRedactionButton;
    this.redactedButton = redactedButton;
    this.unredactedButton = unredactedButton;
    this.downloadButton = downloadButton;
    //this.webSocket = new WebSocket(`ws://${location.host}/todo`);
    this.filters = ""
    this.newCandidates = []
    this.newCandidatesButton = newCandidatesButton
    this.refreshButton = refreshButton
    this.updateList = this.updateList.bind(this);
    this.localList = this.list();
    console.log(this.localList);
    //this.initWS()

    newRedactionButton.addEventListener('click', this.display.newBox);
    window.addEventListener('keypress', ev => {
        if (ev.key === 'n' || ev.key === 'N') this.display.newBox();
        if (ev.key === 'v' || ev.key === 'V') {
            this.display.toggleUnredacted();
            this.display.draw();
            if (this.display.isShowingUnredacted()) {
                unredactedButton.classList.add('hidden');
                redactedButton.classList.remove('hidden');
            } else {
                unredactedButton.classList.remove('hidden');
                redactedButton.classList.add('hidden');
            }
        };
    });
    unredactedButton.addEventListener('click', () => {
        switch (this.currentJob) {
            case "f":
                this.templateDisplay.embedPdf.style.display = "none";
                break;
            default:
                this.display.toggleUnredacted();
                this.display.draw();
        }
        unredactedButton.classList.add('hidden');
        redactedButton.classList.remove('hidden');
    });
    redactedButton.addEventListener('click', () => {
        switch (this.currentJob) {
            case "f":
                this.templateDisplay.embedPdf.style.display = "block";
                break;
            default:
                this.display.toggleUnredacted();
                this.display.draw();
        }
        unredactedButton.classList.remove('hidden');
        redactedButton.classList.add('hidden');
    });
    //downloadButton.addEventListener('click', this.display.save);
    downloadButton.addEventListener('click', () => {
        if (!this.currentJob) return;
        switch (this.currentJob) {
            case "a":
                this.display.submit();
                break;
            case "f":
                this.templateDisplay.save();
                break;
            default:
                throw new Error("invalid currentJob");
        }
    });
    newCandidatesButton.addEventListener("click", (event) => this.handleNewCandidatesClick());
    refreshButton.addEventListener("click", () => this.refreshList(this.filters));
  }

  public async handleNewCandidatesClick() {
    // @ts-ignore
    this.newCandidatesButton.classList.add('hidden');

    // Get the current list
    const list = await this.localList;
    // -- From now until the copying completes, there's no await, so no sync issues --
    const candidates = list.Candidates;
    console.log(candidates, " old")
    for (const candidate of this.newCandidates) {
      console.log(candidate)
      candidates.push(candidate)
    }
    this.newCandidates = [];
    console.log(candidates, " new")
    // -- Copying done, now we can do async again --
    this.updateList(this.filters);
  }

  public initWS(){
    // When a WS connection is opened, send our current timestamp
    this.webSocket.onopen = async () => {
      this.webSocket.send(JSON.stringify({
        "MessageType": "timestamp",
        "data": (await this.localList).Timestamp,
     }));
    };

    // When we receive new candidates, store them and show the "New candidates" button
    this.webSocket.onmessage = (event) => {
      let data = JSON.parse(event.data);
      console.log("Data Received", data);
      this.newCandidates.push(data);
      this.dataReceived();
    };

    this.webSocket.onerror = (errorEvent) => {
      console.error('WebSocket error:', errorEvent);
      this.connectionClosed();
    };

    this.webSocket.onclose = (closeEvent) => {
      console.log('WebSocket connection closed:', closeEvent.code, closeEvent.reason);
      this.connectionClosed();
    };
  }

  public connectionClosed() {
    // @ts-ignore
    document.getElementById("refresh-button").classList.remove('hidden');
  }

  public dataReceived() {
    if (this.newCandidates.length>0){
      // @ts-ignore
      this.newCandidatesButton.classList.remove('hidden');
    }
  }

  public async updateList(filters: any): Promise<void> {
    // Create our own filters object, that's just for this call.
    filters = structuredClone(filters);
    // If it's equal to the existing filters, don't do anything.
    if (recordsEqual(filters, this.filters)) return;
    // Otherwise, set the current filter object to ours.
    this.filters = filters;
    // We then wait for the local list, and for a 150ms timeout.
    const [list, _] = await Promise.all([this.localList, sleep(150)]);
    // After this duration, if the filters object isn't ours, we don't do anything.
    // This prevents a list update for every keypress...
    // Now it's only 150ms after the latest keypress!
    if (this.filters !== filters) return;

    const container = document.getElementById('recent-docs-container');
    if (container && list.Candidates) {
      const listElement = this.createListElement(list.Candidates, filters);
      // Replace all the old children with our single new list
      for (const child of container.children) {
        container.removeChild(child);
      }
      container.appendChild(listElement);
    }
  }

  public refreshList(filters: any): Promise<void> {
    this.localList = this.refresh();
    return this.updateList(filters);
  }

  private async list(): Promise<CandidateSnapshot> {
    const response = await this.authWrapper.fetch('/list');
    if (!response.ok) {
      throw new Error('Non-200 status');
    }
    return await response.json();
  }

  private async refresh(): Promise<CandidateSnapshot> {
    const response = await this.authWrapper.fetch('/refresh');
    if (!response.ok) {
      throw new Error('Non-200 status');
    }
    return await response.json();
  }

  private createListElement(candidates: Candidate[], filters: Record<string,string>): HTMLUListElement {
    const ul = document.createElement('ul');

    console.log(candidates);
    console.log("Scoring...");
    candidates.forEach(candidate => {
        candidate.CandidateName = candidate.FirstName + ' ' + candidate.LastName;
        candidate.score = Object.entries(filters)
            .filter(([_, value]) => value)
            .map(([field, value]) => fuzzysort.single(value, candidate[field]))
            .map(score => score === null ? -1000 : score.score)
            .reduce((a, b) => a + b, 0);
    });
    console.log("Scored!");

    let results: Candidate[] = [...candidates];
    results.sort((a, b) => b.score! - a.score!);

    if (results.length === 0) {
      results = candidates;
    }

    let selected: HTMLElement | null = null;
    let selectedInner: HTMLElement | null = null;

    if (filters.CandidateId || filters.JobTitle || filters.CandidateName || filters.JobId) {
        //@ts-ignore
        document.getElementById('results-header').style.display = 'block';

        results.forEach(candidate => {
          const li = document.createElement('li');
          li.dataset.type = 'item';
          li.addEventListener('click', event => {
            if (selected) selected.classList.remove('selected');
            selected = li;
            selected.classList.add('selected');
          });

          const nameElement = document.createElement('p');
          nameElement.innerText = candidate.FirstName + ' ' + candidate.LastName;
          const candidateId = document.createElement('h3');
          candidateId.innerText = candidate.CandidateId;
          const jobTitle = document.createElement('p');
          jobTitle.innerText = candidate.JobTitle;
          const jobId = document.createElement('h3');
          jobId.innerText = candidate.JobId;
          const documentList = document.createElement('ul');
          //li.append(nameElement, candidateId, jobTitle, jobId, documentList);
          jobId.style.paddingTop = "0.75em";
          li.append(candidateId, nameElement, jobId, jobTitle, documentList);
          ul.appendChild(li);

          for (const idx in candidate.DocumentTypes) {
            for (const job of candidate.DocumentJobs[idx]) {
                let jobName;
                switch (job) {
                    case "a":
                        jobName = "Anonymised";
                        break;
                    case "f":
                        jobName = "Templated";
                        break;
                    default:
                        continue;
                }
                const li = document.createElement('li');
                // @ts-ignore
                let text: string = this.DocumentType[candidate.DocumentTypes[idx].toString()];
                li.innerText = text + " - " + jobName;
                li.innerHTML = "<img src='paperclip.svg'> " + li.innerHTML;
                li.addEventListener('click', _ => {
                    if (selectedInner) selectedInner.classList.remove('selected');
                    selectedInner = li;
                    selectedInner.classList.add('selected');
                    this.selectCandidate(candidate, Number(idx), job);
                });

                li.dataset.type = 'sub item';
                documentList.appendChild(li);
            }
          }
        });
    } else {
        // @ts-ignore
        document.getElementById('results-header').style.display = 'none';
    }

    return ul;
  }

  private async selectCandidate(candidate: Candidate, documentIdx: number, job: string, showButtons: boolean = true) {
    this.currentJob = job;
    switch (job) {
        case "a":
            this.templateDisplay.setVisibility(false);
            this.display.setVisiblity(true);
            await this.display.loadDocument(candidate.GroupId, candidate.DocumentIds[documentIdx]);
            if (showButtons) {
                if (this.display.isShowingUnredacted()) {
                    this.unredactedButton?.classList.add('hidden');
                    this.redactedButton?.classList.remove('hidden');
                } else {
                    this.unredactedButton?.classList.remove('hidden');
                    this.redactedButton?.classList.add('hidden');
                }
                this.downloadButton?.classList.remove('hidden');
                this.newRedactionButton?.classList.remove('hidden');
            }
            break;
        case "f":
            this.fetchAndEditTemplatedDoc(candidate.GroupId, candidate.DocumentIds[documentIdx]);
            if (showButtons) {
                this.templateDisplay.embedPdf.style.display = "none";
                this.unredactedButton?.classList.add('hidden');
                this.redactedButton?.classList.remove('hidden');
                this.downloadButton?.classList.remove('hidden');
                this.newRedactionButton?.classList.add('hidden');
            }
            break;
        default:
            throw new Error("unknown job type");
    }
  }

  /**
   * Select the candidate if exactly one candidate matches the job+candidate ID pair, and the correct document type.
   *
   * Returns a boolean indicating if a candidate was selected.
   */
  public async autoselect(jobId: string, candidateId: string, documentType: number, job: string, showButtons: boolean = true): Promise<boolean> {
      const candidates = (await this.localList).Candidates
        .filter(candidate => candidate.JobId === jobId && candidate.CandidateId === candidateId);
      if (candidates.length === 1) {
          const candidate = candidates[0];
          const documentIdx = candidate.DocumentTypes
            .findIndex(docType => docType.toString() === documentType.toString());
          if (documentIdx >= 0) {
              this.selectCandidate(candidates[0], documentIdx, job, showButtons);
              return true;
          }
      }
      return false;
  }

  async fetchAndEditTemplatedDoc(groupId: string, docId: string) {
    this.display.setVisiblity(false);
    await this.templateDisplay.fetchAndEdit(groupId, docId);
    this.templateDisplay.setVisibility(true);
  }

  editTemplatedDoc(doc: Doc) {
    this.templateDisplay.edit(doc);
    this.display.setVisiblity(false);
    this.templateDisplay.setVisibility(true);
  }
}
