/**
 * decodeBase64 decodes a base64 or base64url string.
 */
function decodeBase64(encoded: string) {
    return atob(encoded.replaceAll('-', '+').replaceAll('_', '/'));
}

export type ParsedHandoverToken = {
    handoverToken: string,

    userToken: string,
    parsedUserToken?: any,
    oid?: string,
    name?: string,
    emails?: string[],
    userInitials?: string,
    shortUserInitials?: string,

    refreshToken?: string,

    service: string,
    serviceToken: string,
    parsedServiceToken?: any,

    data?: string,

    appToken?: any,

    domainId?: string,
    organisationName?: string,
    integrationSettingsId?: number,

    domainIds?: string[],
    organisationNames?: string[],
    integrationSettingsIds?: number[],
    services?: string[],
};

export type ParsedHandoverTokenWithUserInfo = {
    handoverToken: string,

    userToken: string,
    parsedUserToken: any,
    oid: string,
    name: string,
    emails: string[],
    userInitials: string,
    shortUserInitials: string,

    refreshToken?: string,

    service: string,
    serviceToken: string,
    parsedServiceToken: any,

    data?: string,

    appToken?: any,

    domainId?: string,
    organisationName?: string,
    integrationSettingsId?: number,

    domainIds?: string[],
    organisationNames?: string[],
    integrationSettingsIds?: number[],
    services?: string[],
};

export function parseUserInitials(handoverToken: ParsedHandoverToken) {
    let userInitials = "";
    if (typeof handoverToken.name === "string") {
        // Remove double spaces, and spaces at the start or end.
        const cleanName = handoverToken.name.replace(/  +/g, ' ').replace(/(^ )|( $)/g, '');
        const names = cleanName.split(' ');
        // Map each name to its upper-case first letter and join them to form the initials.
        userInitials = names.filter(name => name).map(name => name[0].toUpperCase()).join('');
    }
    if (!userInitials && typeof handoverToken.emails === 'object' && handoverToken.emails.length) {
        const initialsFromEmail = (email: string) => email
            .split('@')[0]
            .split('.')
            .filter(part => part)
            .map(part => part[0].toUpperCase())
            .join('');
        const potentialInitials = handoverToken.emails.map(initialsFromEmail);
        // Try and find initials of length 2, then 1, then choose anything.
        userInitials = potentialInitials.filter(initial => initial.length === 2)[0]
            || potentialInitials.filter(initial => initial.length === 1)[0]
            || potentialInitials.filter(initial => initial.length)[0]
            || "";
    }
    let shortUserInitials = userInitials;
    if (shortUserInitials.length > 2) {
        shortUserInitials = userInitials[0] + userInitials[userInitials.length - 1];
    }
    return {userInitials, shortUserInitials};
}

export function parseHandoverTokenWithUserInfo(token: string): ParsedHandoverTokenWithUserInfo {
    return parseHandoverToken(token, true, true) as ParsedHandoverTokenWithUserInfo;
}

export function parseHandoverToken(
    token: string,
    parseUserInfo: boolean = true,
    includeParsedTokens: boolean = false,
): ParsedHandoverToken {
    // Parse optional data postfix
    let data;
    const dataIdx = token.indexOf(':');
    if (dataIdx > -1) {
        data = token.substr(dataIdx + 1);
        token = token.substr(0, dataIdx);
    }

    const parts = token.split('.');
    if ((parts.length !== 7 || parts[0] !== "0") && (parts.length !== 11 || parts[0] !== "1") && (parts.length !== 9 || parts[0] !== "2" && parts[0] !== "3") && (parts.length !== 8 || parts[0] !== "4" && parts[0] !== "5")) {
        throw new Error(`invalid version ${parts.length && parts[0]} token of length ${parts.length}`);
    }
    const serviceToken = JSON.parse(decodeBase64(parts[5]));
    const result: ParsedHandoverToken = {
        handoverToken: token,
        userToken: parts.slice(1, 4).join('.'),
        serviceToken: parts.slice(4, 7).join('.'),
        service: serviceToken["aud"],
        data: data,
    };
    switch (result.service) {
        case "test":
        case "analytics":
        case "integration-access-admin":
            result.domainId = serviceToken["mv-did"];
            result.organisationName = serviceToken["mv-org-name"];
            result.integrationSettingsId = parseInt(serviceToken["mv-isid"], 10);
            break;
        case "domain-selection":
            result.domainIds = serviceToken["mv-dids"].split('~');
            result.organisationNames = serviceToken["mv-org-names"].split('~');
            break;
        case "integration-selection":
            result.domainId = serviceToken["mv-did"];
            result.organisationName = serviceToken["mv-org-name"];
            result.integrationSettingsIds = serviceToken["mv-isids"].split('~').map((id: string) => parseInt(id, 10));
            break;
        case "service-selection":
            result.domainId = serviceToken["mv-did"];
            result.organisationName = serviceToken["mv-org-name"];
            result.integrationSettingsId = parseInt(serviceToken["mv-isid"], 10);
            result.services = serviceToken["mv-svcs"].split('~');
            break;
    }
    if (includeParsedTokens) {
        result.parsedServiceToken = serviceToken;
    }
    if (parseUserInfo) {
        const userToken = JSON.parse(decodeBase64(parts[2]));
        result.oid = userToken["oid"];
        result.name = userToken["name"];
        result.emails = userToken["emails"] || [];
        if (includeParsedTokens) {
            result.parsedUserToken = userToken;
        }
        const {userInitials, shortUserInitials} = parseUserInitials(result);
        result.userInitials = userInitials;
        result.shortUserInitials = shortUserInitials;
    }
    if (parts[0] === "1") {
        result.appToken = parts[7];
        result.refreshToken = parts.slice(8, 11).join('.');
    } else if (parts[0] === "2") {
        result.appToken = parts[7];
        result.refreshToken = decodeBase64(parts[8]);
    } else if (parts[0] === "3") {
        result.appToken = parts[7];
        result.refreshToken = parts[8];
    } else if (parts[0] === "4" || parts[0] === "5") {
        result.refreshToken = parts[7];
    }
    return result;
}

export function getHandoverToken(tokenId: string): Promise<string | null> {
    return fetch("https://simple-store.mevitae.net/api/get", {
        method: "POST",
        headers: {
            "X-MeVitae-Key": "public-analytics",
        },
        body: tokenId,
        mode: "cors",
        cache: "no-store",
        redirect: "error",
    }).then(resp => {
        if (resp.status === 404) return null;
        if (resp.status !== 200) {
            throw new Error("Simple store status was not 200");
        }
        return resp.text();
    })
}

export function getParsedHandoverToken(
    tokenId: string,
    parseUserInfo = true,
    includeParsedTokens = false,
): Promise<ParsedHandoverToken | null> {
    return getHandoverToken(tokenId).then(token => typeof token === "string" && parseHandoverToken(
        token,
        parseUserInfo,
        includeParsedTokens,
    ) || null);
}

export function refreshHandoverToken(
    userToken: string,
    serviceToken: string,
    refreshToken: string,
    parseUserInfo = true,
    includeParsedTokens = false
): Promise<ParsedHandoverToken | null> {
    return doTokenRefresh(userToken, serviceToken, refreshToken)
        .then(token => typeof token === "string" && parseHandoverToken(
            token,
            parseUserInfo,
            includeParsedTokens,
        ) || null);
}

export function doTokenRefresh(userToken: string, serviceToken: string, refreshToken: string): Promise<string | null> {
    const body = new FormData();
    body.append("accessToken", userToken);
    body.append("serviceToken", serviceToken);
    body.append("refreshToken", refreshToken);
    return fetch("https://accounts.mevitae.com/refresh", {
        method: "POST",
        body,
        mode: "cors",
        cache: "no-store",
        redirect: "error",
    }).then(resp => {
        if (resp.status !== 200) {
            throw new Error("Refresh status was not 200");
        }
        return resp.text();
    });
}

/**
 * Parse a querystring. If no querystring is passed, `location.search` is used.
 *
 * This returns an object where each argument is a key, associated with string. If no argument value
 * is provided, an empty string is used.
 */
export function parseSearchArgs(search?: string | null): Record<string, string> {
    if (!search) search = location.search;
    const args: Record<string, string> = {};
    if (search.length > 0) {
        // Remove the initial '?' if present.
        if (search[0] === '?') search = search.substring(1);
        // Parse each argument
        search.split('&').forEach(arg => {
            // The argument value defaults to an empty string...
            let value = "";
            // Unless specified...
            const eq = arg.indexOf('=');
            if (eq > 0) {
                value = arg.substring(eq + 1);
                arg = arg.substring(0, eq);
            }
            // Both the name and value get decoded.
            args[decodeURIComponent(arg)] = decodeURIComponent(value);
        });
    }
    return args;
}

/**
 * Format an object of arguments into a querystring.
 */
export function formatSearchArgs(args: Record<string, string>): string {
    let search = '';
    for (const arg in args) {
        if (search.length === 0) search += '?';
        else search += '&';
        search += arg + '=' + encodeURIComponent(args[arg]);
    }
    return search;
}

export function getTokenIdFromSearchArgs(
    search?: string | null,
    removeFromQuery: boolean = true,
): {
    search: Record<string, string>,
    tokenId?: string | null,
} {
    const args = parseSearchArgs(search);
    const {tokenId} = args;
    console.log("Token ID:", tokenId);
    if (!tokenId) return {
        search: args,
        tokenId: null,
    };
    delete args.tokenId;
    // @ts-ignore
    if (removeFromQuery) window.history.replaceState(null, null, formatSearchArgs(search) || "?");
    return {
        search: args,
        tokenId,
    };
}

export function clearSessionHandoverToken() {
    localStorage.removeItem("handoverToken");
}

export async function getSessionHandoverToken(): Promise<{
    search: Record<string, string>,
    handoverToken?: ParsedHandoverTokenWithUserInfo | null,
}> {
    const {tokenId, search} = getTokenIdFromSearchArgs();
    let handoverToken: string | null;
    if (!tokenId) {
        // If we don't have a new token ID, get the token from storage.
        handoverToken = localStorage.getItem("handoverToken");
    } else {
        // If we do have a new token ID, fetch the new token
        handoverToken = await getHandoverToken(tokenId);
        // Update the stored handover token
        if (handoverToken) localStorage.setItem("handoverToken", handoverToken);
        else localStorage.removeItem("handoverToken");
    }
    if (!handoverToken) {
        return {search, handoverToken: null};
    }
    return {
        search,
        handoverToken: parseHandoverTokenWithUserInfo(handoverToken),
    };
}

export function getParsedHandoverTokenFromSearchArgs(
    search?: string | null,
    parseUserInfo = true,
    includeParsedTokens = false,
    removeFromQuery = true,
): Promise<{
    search: Record<string, string>,
    handoverToken?: ParsedHandoverToken | null,
}> {
    const {search: args, tokenId} = getTokenIdFromSearchArgs(search, removeFromQuery);
    if (!tokenId) return Promise.resolve({
        search: args,
        handoverToken: null,
    });
    return getParsedHandoverToken(tokenId, parseUserInfo, includeParsedTokens).then(handoverToken => ({
        handoverToken,
        search: args,
    }));
}
